import axios, { ErrorResponse } from './../axios';
import { AuthSignInForm, AuthSignInResponse, AuthSignUpForm } from './dto';
import { SIGN_IN, SIGN_UP } from './routes';


export const performSignIn = async(signInForm: AuthSignInForm): Promise<AuthSignInResponse | null> => {
  try {
    const response = await axios.post(SIGN_IN, signInForm);
    return response.data as AuthSignInResponse;
  }
  catch (error) {
    console.log('Error while signing in', error);
    return null;
  }
}


export const performSignUp = async(signUpForm: AuthSignUpForm): Promise<AuthSignInResponse | string> => {
  try {
    const {avatar, ...form} = signUpForm;
    if (avatar === null) {
      const response = await axios.post(SIGN_UP, form);
      return response.data as AuthSignInResponse;
    }
    else {
      const formData = new FormData();
      Object.entries(signUpForm).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await axios.post(SIGN_UP, formData);
      return response.data as AuthSignInResponse;
    }
  }
  catch (error) {
    console.log('Error while signing up', error);
    const e = error as ErrorResponse;
    return e.message;
  }
}
