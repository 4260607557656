import React from "react";

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { STATIC_BASE_PATH } from "../../conf";
import { AuthSignUpForm } from "../../api/auth/dto";
import { performSignUp } from "../../api/auth/api";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const SignUp: React.FC = () => {
  const navigate = useNavigate();

  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = React.useState('');

  const [form, setForm] = React.useState<AuthSignUpForm>({
    email: '',
    password: '',
    username: '',
    avatar: null,
    bio: '',
    github_profile: '',
    linkedin_profile: '',
    youtube_channel: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = await performSignUp(form);
    if (typeof response === 'string') {
      setFormSubmitErrorMessage(response);
      return;
    }
    window.location.href="/";
  }

  const handleSignInViewChange = () => {
    navigate('/sign-in');
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setForm({...form, avatar: file});
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "bio" && event.target.value.length > 80) {
      return;
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }


  const handleValidation = () => {
    return (
      form.email !== ""
      && form.username !== ""
      && form.username !== ""
      && form.github_profile !== ""
    )
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent sx={{ width: "100%"}}>
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
              <img
                src={`${STATIC_BASE_PATH}/logo.png`}
                style={{ width: "auto", maxHeight: "200px" }}
                loading="lazy"
              />
            </Box>
            <Typography
              component="h1"
              variant="h4"
            >
              Sign Up 🚀
            </Typography>
            <Typography
              variant="subtitle1"
            >
              Already registered? <Link onClick={handleSignInViewChange} sx={{cursor: "pointer"}}>Sign In!</Link>
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  autoComplete="email"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  onChange={handleFormChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="username">Username</FormLabel>
                <TextField
                  id="username"
                  type="username"
                  name="username"
                  placeholder="Your Username"
                  required
                  fullWidth
                  variant="outlined"
                  helperText="This will be your public profile URL. Both username and email must be globally unique in the system."
                  onChange={handleFormChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="bio">Bio</FormLabel>
                <TextField
                  id="bio"
                  type="bio"
                  name="bio"
                  placeholder="Python Developer & Code enthusiast"
                  required
                  fullWidth
                  variant="outlined"
                  helperText={`${form.bio.length}/80 Just a very short description about yourself.`}
                  onChange={handleFormChange}
                  value={form.bio}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="github_profile">Github Profile URL</FormLabel>
                <TextField
                  id="github_profile"
                  type="github_profile"
                  name="github_profile"
                  placeholder="Your Github Profile URL"
                  fullWidth
                  variant="outlined"
                  onChange={handleFormChange}
                  helperText="You need to have a Github account for being able to fully take advantages from Code Smash since most of our features are itnegrated into GitHub."
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  name="password"
                  placeholder="••••••"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  fullWidth
                  variant="outlined"
                  onChange={handleFormChange}
                />
              </FormControl>
              <FormControl>
              {
                !form.avatar ?
                  <>
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Profile Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </Button>
                    <Typography variant="caption" align="center">
                      Profile image will be used as your avatar. This is a not required field.
                    </Typography>
                  </>
                :
                  <>
                    <Button
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                      onClick={() => setForm({...form, avatar: null})}
                    >
                      Uploaded File: {form.avatar.name} (click to remove)
                    </Button>
                  </>
              }
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleValidation}
              >
                Sign Up
              </Button>

              <Typography variant="body1" align="center" color="error">
                {formSubmitErrorMessage}
              </Typography>

            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default SignUp;
