import React from "react";

import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";

import List from '@mui/material/List';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListItem from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/ListItemIcon';

import { STATIC_BASE_PATH } from "../../conf";
import { Typography } from "@mui/material";


interface FeaturBoxProps {
  title: string;
  description: string;
  image: string;
}

const FeaturBox: React.FC<FeaturBoxProps> = ({...props}) => {

  return (
    <Stack
      sx={{
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          objectFit: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '200px',
          aspectRatio: '1 / 1',
          borderRadius: '50%',
        }}
      />
      <Box>
        <Typography variant="h6" align="center">
          {props.title}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {props.description}
        </Typography>
      </Box>
    </Stack>
  );
}

interface FeatureItemProps {
  description: string;
}

const featureItems: FeatureItemProps[] = [
  {
    description: "Supported programing languages: Python, Javascript, Typescript, Java and many more."
  },
  {
    description: "Supported engineering fields: Web development, Machine Learning, Data Science, and many more."
  },
  {
    description: "High amount of possible Use Cases which can be generated by AI."
  },
  {
    description: "Use cases are related to high diversity of industries."
  },
  {
    description: "Get Code Review by AI and by other users."
  },
  {
    description: "Individual feedback to code can be provided by AI and other users."
  },
  {
    description: "Find a team for your knowledge level and programing language preferences."
  },
];


const Features: React.FC = () => {
  const navigate = useNavigate();

  const handleSignInViewChange = () => {
    navigate('/sign-in');
  }

  return (
    <Box>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            mt: 4,
            mb: 4,
          }}
        >
          Features
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <FeaturBox
              title="No problems on lack of ideas"
              description="Tasks and subtasks will be generated based on your preferences by AI."
              image={`${STATIC_BASE_PATH}/feature1.jpeg`}
            />
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <FeaturBox
              title="No isolation. Colaboration is key!"
              description="It's focus to come together and collaborate with others. You'll get feedback by others and by AI. And you nee to give feedback to others. Both is required for being able to complete a task."
              image={`${STATIC_BASE_PATH}/feature2.jpeg`}
            />
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <FeaturBox
              title="Gain your programing skills by practicing"
              description="Routine is the key to success. By using Code Smash you have no excuse to not practice for reasons like no idea, no teammates, or whatever."
              image={`${STATIC_BASE_PATH}/feature3.jpeg`}
            />
          </Grid>
        </Grid>

        <List
          sx={{
            mt: 4,
          }}
        >
          {
            featureItems.map((item, index) => {
              return (
                <ListItem
                  key={index}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '1rem',
                    }}
                  >
                    <IconButton sx={{color: "green"}}>
                      <CheckCircleOutlineIcon />
                    </IconButton>
                    <Typography variant="subtitle1">
                    {item.description}
                    </Typography>

                  </span>
                </ListItem>
              );
            })
          }
        </List>
      </Container>
    </Box>
  );
};

export default Features;
