import React from "react";

import { Routes, Route } from "react-router-dom";

import Base from "./components/base/Base";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";

const App: React.FC = () => {
  return (
    <Base>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/sign-in" element={<Login/>} />
        <Route path="/sign-up" element={<SignUp/>} />
      </Routes>
    </Base>
  );
};

export default App;
