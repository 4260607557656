import React from "react";

import { useNavigate } from 'react-router-dom';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { STATIC_BASE_PATH } from "../../conf";

const NavBar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  }

  return (
    <AppBar
      style={{
        backgroundColor: "#fff",
        borderBottom: "1px solid",
        borderBottomColor: "rgba(0,0,0,.2)",
      }}
      position="static"
      elevation={0}
    >
      <Toolbar sx={{ display: "flex" }}>
      <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            px: 0,
            cursor: "pointer"
          }}
          onClick={handleLogoClick}
        >
          <img
            src={`${STATIC_BASE_PATH}/logo192.png`}
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          />
          <Typography variant="h6" color="primary">
            Code Smash
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
