import React from "react";

import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { teal } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface RoadmapItem {
  title: string;
  description: string | null;
  type: 'feature' | 'improvement';
}

const roadmapItems: RoadmapItem[] = [
  {
    title: 'Strategy for none blocking prompts',
    description: 'While the server is waiting for ai responses, it is temporarily unavailable for other requests. Prompting and requests should be decoupled by an event driven strategy.',
    type: 'improvement',
  },
  {
    title: "Imrprove Code review quality made by AI",
    description: "The full diff of code gets passed into the AI model. Instead it should be split into smaller parts and the AI model should be able to request more context if needed.",
    type: 'improvement',
  },
  {
    title: "Imrprove Code review quality made by AI",
    description: "The tasks which are currently generated are more sticked to very basic use cases like Todo lists. An imrpovement should be made to increase the diversity of AI generated Use-Cases.",
    type: 'improvement',
  },
  {
    title: "Finish CRUD Operations",
    description: "it's only possible to create, assign or join groups. It Should also be possible to unassign and leave groups.",
    type: 'improvement',
  },
  {
    title: "Conditional Engineering Field creation",
    description: "At the moment there are no conditions for task creation. It's currently possible to choose Python for mobile development. A future version should include more constraints for challenge creation.",
    type: 'improvement',
  },
  {
    title: "Implement Smash Challenge Filtering",
    description: "The challenges are currently displayed all at once. It should be possible to filter them (i.e. by programing languages).",
    type: 'feature',
  },
  {
    title: "Link Challenge with Repository at Github",
    description: "For better colaboration, an AI generated Challenge should be directly linked to a Github repository.",
    type: 'feature',
  },
  {
    title: "Create Code Smash UI Tool",
    description: "AI Feedback is currently made by our CLI written in Python. This is very user unfriendly. Instead it's intended to migrate to an Electron Descktop Application for being able to use the CLI features in an UI context.",
    type: 'feature',
  },
  {
    title: "Company Integration",
    description: "Collaboration with companies are planned. Companies should be able to search and find potential employees.",
    type: 'feature',
  },
  {
    title: "Follow User",
    description: "It should be possible to follow other users. This is a basic feature for social networks.",
    type: 'feature',
  },
  {
    title: "User Statistics",
    description: "It should be possible to see statistics about the user. For example how many challenges the user has solved.",
    type: 'feature',
  }
]

const Roadmap: React.FC = () => {
  const navigate = useNavigate();

  const handleSignInViewChange = () => {
    navigate('/sign-in');
  }

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: teal[50],
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ mt: 4, mb: 4 }}
        >
          🎯 Feature- and Improvement Roadmap ✈️
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roadmapItems.map((roadmapItem, index) => (
                <TableRow key={index}>
                  <TableCell>{roadmapItem.title}</TableCell>
                  <TableCell>{roadmapItem.description}</TableCell>
                  <TableCell>{roadmapItem.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Roadmap;
