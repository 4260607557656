import React from "react";

import Box from "@mui/material/Box";

import Thumbnail from "./Thumbnail";
import Features from "./Features";
import Roadmap from "./Roadmap";

const LandingPage: React.FC = () => {
  return (
    <Box>
      <Box>
        <Thumbnail/>
      </Box>
      <Box>
        <Features />
      </Box>
      <Box>
        <Roadmap />
      </Box>
    </Box>
  );
};

export default LandingPage;
