import axios from 'axios';
import { ENV, API_TOKEN } from './../conf'

import Cookies from 'js-cookie';


export interface ErrorResponse {
  status: number;
  data: Object;
  message: string;
}

const instance = axios.create({});

instance.interceptors.request.use(config => {
  if (ENV === 'prod' as string) {
    // Use cookie Authentication for prod
    // don't set this in dev, Django won't be able to authenticate you
    config.withCredentials = true;
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
  }
  return config;
});

export default instance;
