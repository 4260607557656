import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import NavBar from "./NavBar";
import Footer from "./Footer";

interface BaseProps {
  children: React.ReactNode;
}

const Base: React.FC<BaseProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* NAVBAR */}
      <CssBaseline />
      <NavBar />

      {/* CONTENT */}
      <Box component="main">
        {children}
      </Box>

      {/* FOOTER */}
      <Footer />
    </Box>
  );
};

export default Base;
