import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


import { teal } from "@mui/material/colors";

import { useNavigate } from 'react-router-dom';

import { STATIC_BASE_PATH } from "../../conf";


const Thumbnail: React.FC = () => {
  const navigate = useNavigate();

  const handleSignInViewChange = () => {
    navigate('/sign-in');
  }

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        height: '75vh',
        backgroundImage: `url(${STATIC_BASE_PATH}/thumbnail.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        objectFit: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          width: '100%',
          height: '100%',
        }}
      >

        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{
              alignItems: 'center',
              width: { xs: '100%', sm: '70%' },
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              p: 2,
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              }}
            >
              Level up&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontSize: 'inherit',
                  color: teal[500],
                }}
              >
                <b>your</b>
              </Typography>&nbsp;skills
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                color: 'text.secondary',
                width: { sm: '100%', md: '80%' },
              }}
            >
              Improve your coding skills today!
              Generate a challenge by AI, join a team, and build your programming expertise through collaborative and hands on practice.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ minWidth: 'fit-content', backgroundColor: teal[500] }}
              onClick={handleSignInViewChange}
            >
              Sign In Now!
            </Button>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Thumbnail;
