import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={{ xs: "center", md: "center" }}
        >
          <Stack direction="row" spacing={2}>
            <Typography variant="body1">
              {"© "} {new Date().getFullYear()}{" "}
              <Link color="inherit" href="/">
                JangasCodingplace
              </Link>
              . All rights reserved
            </Typography>
            {/* <Typography>⋅</Typography>
            <Link href="/imprint">Imprint</Link>
            <Typography>⋅</Typography>
            <Link href="/data-privacy">Data Privacy</Link> */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Link
              href="https://github.com/JangasCodingplace"
              aria-label="Github"
              target="_blank"
            >
              <GitHubIcon />
            </Link>
            <Link
              href="https://www.linkedin.com/in/jgoesser/"
              aria-label="LinkedIn"
              target="_blank"
            >
              <LinkedInIcon />
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
