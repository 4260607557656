import React from "react";

import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import { performSignIn } from "../../api/auth/api";
import { STATIC_BASE_PATH } from "../../conf";


const App: React.FC = () => {
  const navigate = useNavigate();

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = React.useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (emailError || passwordError) {
      return;
    }
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    const response = await performSignIn({
      email: data.get('email') as string,
      password: data.get('password') as string,
    });
    if (response === null) {
      setFormSubmitErrorMessage('Invalid email or password.');
      return;
    }

    window.location.href = '/';
  }

  const handleSignUpViewChange = () => {
    navigate('/sign-up');
  }

  const validateInputs = () => {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;

    let isValid = true;

    setEmailError(false);
    setEmailErrorMessage('');
    setPasswordError(false);
    setPasswordErrorMessage('');
    setFormSubmitErrorMessage('');

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    }

    if (!password.value || password.value.length < 4) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be set.');
      isValid = false;
    }
    return isValid;
  }


  return (
    <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent sx={{ width: "100%"}}>
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
              <img
                src={`${STATIC_BASE_PATH}/logo.png`}
                style={{ width: "auto", maxHeight: "200px" }}
                loading="lazy"
              />
            </Box>
            <Typography
              component="h1"
              variant="h4"
            >
              Sign in
            </Typography>
            <Typography
              variant="subtitle1"
            >
              Account does not exist already? <Link onClick={handleSignUpViewChange} sx={{cursor: "pointer"}}>Sign Up!</Link>
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  error={emailError}
                  helperText={emailErrorMessage}
                  id="email"
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  autoComplete="email"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  color={emailError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  name="password"
                  placeholder="••••••"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  fullWidth
                  variant="outlined"
                  color={passwordError ? 'error' : 'primary'}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={validateInputs}
              >
                Sign in
              </Button>

              <Typography variant="body1" align="center" color="error">
                {formSubmitErrorMessage}
              </Typography>

            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default App;
